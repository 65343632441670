import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import axios from 'axios';

// componentes
import ModalVejaMais from './ModalVejaMais';
import Cabecalho from './Cabecalho';
import Banners from './Banners';
import ProdutosConsultar from './Produtos/Consultar';
import ProdutosLeitura from './Produtos/Leitura';
import Loja from './Loja';
import Rodape from './Rodape';
import Contato from './Contato';

// site
export default function Site({
    produto=false
}){

    // estados
    const location = useLocation();
    const history = useHistory();
    const [dados, alterarDados] = useState(null);
    const [vejaMais, alterarVejaMais] = useState(null);

    // carrega os dados iniciais
    async function carregar(){

        try{

            let { data } = await axios.get(`/inicio`);
            alterarDados(data);
            
            // redireciona urls
            if(location.hash !== ''){
                window.location.href = location.hash;
            }
            
        }catch({}){}
    }

    // inicializa
    useEffect(() => {
        carregar();
    }, []);

    useEffect(() => {
        // redireciona urls
        if(location.hash !== ''){
            window.location.href = location.hash;
        }
    }, [location.hash]);


    if(dados === null){
        return <></>
    }

    return <>

        {vejaMais &&
            <ModalVejaMais 
                {...vejaMais}
                fechou={() => {
                    alterarVejaMais(null);
                }}
            />
        }

        <Cabecalho 
            categorias={dados.categorias}
        />
        { location.pathname === '/' && 
            <Banners 
                imagens={dados.banners.registros}
            />
        }
        {!produto && 
            <ProdutosConsultar 
                alterarVejaMais={alterarVejaMais}
            />
        }

        {produto && 
            <ProdutosLeitura />
        }
        <Loja 
            dados={dados.empresa.dados}
            imagens={dados.empresa.arquivos}
            verMais={() => {
                alterarVejaMais({
                    titulo: 'A Loja',
                    texto: dados.empresa.dados.texto,
                    imagens: dados.empresa.arquivos,
                    habilitarMapa: true
                });
            }}
        />
        { location.pathname === '/' && 
            <Contato 
                dados={dados.contato.dados}
            />
        }
        <Rodape />
    </>
}