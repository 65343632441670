import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import queryString  from 'query-string';

// componentes
import { ProdutosComponent } from './components';
import { Container } from 'react-bootstrap';
import Produto from './Produto';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';


// produtos
export default function Consultar({
    alterarVejaMais
}){

    // estados
    const { categoria } = useParams();
    const location = useLocation();

    // parametros iniciais
    const [parametros, alterarParametros] = useState(Object.assign({
        categorias: detectarCategoriaId(categoria) > 0 ? [detectarCategoriaId(categoria)] : [],
        feminino: [],
        masculino: [],
        grupos: [],
        descricao: ''
    }, queryString.parse(location.search, {arrayFormat: 'bracket'})));
    const [carregando, alterarCarregando] = useState(true);
    const [consulta, alterarConsulta] = useState([]);

    // faz a consulta dos produtos
    async function consultar(){
        alterarCarregando(true)

        try{

            // faz a requisição
            let { data } = await axios.get(`/produtos`, { params: parametros });
            alterarConsulta(data.registros);
            alterarCarregando(false);
            if(location.hash !== ""){
                window.location.href = location.hash;
            }

        }catch({response}){}

    }

    // retorna o id da categoria pela url
    function detectarCategoriaId(categoria){
        let idCategoria = 1;
        switch(categoria){
            case 'busca': idCategoria = 0; break;
            case 'joias': idCategoria = 2; break;
            case 'relogios': idCategoria = 3; break;
            case 'otica': idCategoria = 4; break;
            case 'oculos-de-sol': idCategoria = 5; break;
            case 'acessorios': idCategoria = 6; break;
        }
        return idCategoria;
    }

    // // se os parametros da url forem alterados
    // useEffect(() => {
    //     alterarParametros({...parametros, ...{feminino: [], masculino: [], grupos: []}, ...queryString.parse(location.search, {arrayFormat: 'bracket'})});
    // }, [location.search]);

    // ao alterar categoria
    useEffect(() => {
        alterarParametros({...parametros, descricao: '', categorias: detectarCategoriaId(categoria) > 0 ? [detectarCategoriaId(categoria)] : [], ...{feminino: [], masculino: [], grupos: []}, ...queryString.parse(location.search, {arrayFormat: 'bracket'})});
    }, [categoria, location.search]);

    // inicializa
    useEffect(() => {
        consultar();
    }, [parametros]);

    if(carregando){
        return <Container className="text-center p-4">
            <FontAwesomeIcon icon={faSpinner} pulse /> Aguarde!
        </Container>
    }

    return <ProdutosComponent id="produtos">
        <Container>
            <h2>
                {detectarCategoriaId(categoria) === 0 && 'Busca'}
                {detectarCategoriaId(categoria) === 1 && 'Lançamentos'}
                {detectarCategoriaId(categoria) === 2 && 'Jóias'}
                {detectarCategoriaId(categoria) === 3 && 'Relógios'}
                {detectarCategoriaId(categoria) === 4 && 'Ótica'}
                {detectarCategoriaId(categoria) === 5 && 'Óculos de sol'}
                {detectarCategoriaId(categoria) === 6 && 'Acessórios'}
            </h2>
            <hr />

            <div className="lista">
                {consulta.map(registro => 
                    <Produto 
                        key={registro.dados.id}
                        dados={registro.dados}
                        imagens={registro.imagens}
                        clicouVerMais={() => {
                            alterarVejaMais({
                                titulo: registro.dados.descricao,
                                // texto: dados.empresa.dados.texto,
                                imagens: registro.imagens
                            });
                        }}
                    />
                )}
            </div>
        </Container>
    </ProdutosComponent>

}