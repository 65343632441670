
import React from 'react';

// components
import { faCopyright } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container } from 'react-bootstrap';

// componente
export default function Rodape(){

    return <>
        <hr />
        <Container className='py-3 text-center pb-3'>
            <FontAwesomeIcon icon={faCopyright} /> Rubi Design Digital
        </Container>
    </>

}