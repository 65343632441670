import React from 'react';

// loja
import { LojaComponent } from './components';
import { ButtonComponent } from './../components';
import { Row, Col, Container } from 'react-bootstrap';

import temp1Jpg from './hd1.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMap, faMapMarked, faMarker } from '@fortawesome/free-solid-svg-icons';

// loja
export default function Loja({
    dados,
    imagens,
    verMais
}){


    return <LojaComponent
        id='a-loja'
    >
        <Container>
            <h2>A Loja</h2>
            <hr />
            <Row className='align-items-center mt-5'>
                <Col lg={6}>
                    <div className='imagem'>
                        {imagens.length > 0 &&
                            <img src={`${process.env.REACT_APP_URL_ARQUIVOS}/arquivos/geral/${imagens[0].arquivo}`} />
                        }
                    </div>
                </Col>
                <Col lg={6}>
                    <div className="descricao">
                        <p>
                            {dados.texto.length >= 600 ? (dados.texto.substring(0, 600) + ` ...`) : (dados.texto)}
                        </p>

                        <div className="veja-mais">
                            <ButtonComponent
                                primary={true}
                                onClick={verMais}
                            >
                                VEJA MAIS

                                <FontAwesomeIcon icon={faMapMarked} className="ml-3" />
                            </ButtonComponent>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </LojaComponent>
}