import styled from 'styled-components';

export const ProdutoComponent = styled.div.attrs({
    className: ``
})`

    img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        cursor: pointer;


        @media screen and (max-width: 768px){
            height: 250px;
        }


        @media screen and (max-width: 411px){
            
        }
    }

    .dados {
        

        p {
            margin: 0;
        }

        .compre-agora {
            font-weight: bold;
            vertical-align: middle;
            display: flex;
            align-items: center;
            white-space: nowrap;
            font-size: 0.8rem;
        }
    }

`