import { combineReducers } from 'redux';
import { withReduxStateSync } from 'redux-state-sync';

// importa reducers e actions
import LoginReducer from './Sistema/Login/LoginReducer';

// unifica reducers
let rootReducer  = combineReducers({
    loginReducer: LoginReducer
});

// retorna reducer global
export default withReduxStateSync(rootReducer);