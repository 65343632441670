import React from 'react';

// componentes
import { ToastContainer } from 'react-toastify';
import Sistema from './Sistema';

function App() {
    return <>
        <ToastContainer 
            toastClassName='toast fade show p-0'
            closeOnClick={false}
            hideProgressBar={true}
            closeButton={false}
        />
        <Sistema />
    </>
}

export default App;
