import styled from 'styled-components';

export const LeituraComponent = styled.div.attrs({
    className: ``
})`

    .galeria {

        .imagem {
            height: 100px;
            cursor: pointer;
        }
    }


    .dados {
        

        p {
            margin: 0;
        }

        .compre-agora {
            font-weight: bold;
            vertical-align: middle;
            display: flex;
            align-items: center;
            white-space: nowrap;
            font-size: 0.8rem;
        }
    }

`;