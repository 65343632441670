import React, { useState, useLayoutEffect, useEffect } from 'react';
import { useRouteMatch, useParams, useHistory, useLocation } from 'react-router-dom';
import queryString  from 'query-string';

// componentes
import { CabecalhoComponent } from './components';
import LinkMenu from './LinkMenu';
import { Navbar, Dropdown, Nav, NavDropdown, ButtonGroup, DropdownButton, Form, Button } from 'react-bootstrap';
import logoSvg from './logo.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faFacebookF, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons';


// componente
export default function Cabecalho({
    categorias
}){

    // estados
    const location = useLocation();
    const history = useHistory();
    const { path, url } = useRouteMatch();
    const { categoria } = useParams();
    const [ menuFixo, alterarMenuFixo ] = useState(0);
    const [pesquisa, alterarPesquisa] = useState(Object.assign({
        descricao: ''
    }, queryString.parse(location.search, {arrayFormat: 'bracket'})));


    // aplica a busca
    async function aplicarBusca(){
        let encodeQuery = queryString.stringify({descricao: pesquisa.descricao }, {arrayFormat: 'bracket'});
        history.push({
            pathname: `/produtos/busca`,
            search: "?"+encodeQuery
        });
    }

    useLayoutEffect(() => {
        
        function updatePositionZ() {
            alterarMenuFixo(window.scrollY);
        }
        window.addEventListener('scroll', updatePositionZ);
        return () => window.removeEventListener('scroll', updatePositionZ);
    }, []);

    useEffect(() => {
        alterarPesquisa(Object.assign({
            descricao: ''
        }, queryString.parse(location.search, {arrayFormat: 'bracket'})))
    }, [location.search]);


    return <CabecalhoComponent
        menuFixo={menuFixo} 
    >
        <Navbar bg="white" expand="lg">
            <div className="logo-mobile mobile">
                <Navbar.Brand href="/" className="logo">
                    <img src={logoSvg} />
                </Navbar.Brand>
            </div>
            <div className="redes-sociais mobile">
                <Form inline>
                    <a variant="outline-success" className="p-2" href="https://www.instagram.com/rhjoalheriaeotica/" target="_blank">
                        <FontAwesomeIcon icon={faInstagram} />
                    </a>
                    <a variant="outline-success" className="p-2" href="https://www.facebook.com/rh.joalheriaeotica/" target="_blank">
                        <FontAwesomeIcon icon={faFacebookF} />
                    </a>
                </Form>
            </div>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className="navbar-desktop">
                <Navbar.Brand href="/" className="desktop logo-mobile logo mx-5">
                    <img src={logoSvg} />
                </Navbar.Brand>
                <Nav className="mr-auto itens-menu">
                    <LinkMenu 
                        descricao='Joias' 
                        href="/produtos/joias" 
                        feminino={categorias['joias'].feminino}
                        masculino={categorias['joias'].masculino}
                        focado={categoria === 'joias'}
                    />
                    <LinkMenu 
                        descricao='Relógios' 
                        href="/produtos/relogios" 
                        feminino={categorias['relogios'].feminino}
                        masculino={categorias['relogios'].masculino}
                        focado={categoria === 'relogios'}
                    />
                    <LinkMenu 
                        descricao='Ótica' 
                        href="/produtos/otica" 
                        feminino={categorias['otica'].feminino}
                        masculino={categorias['otica'].masculino}
                        focado={categoria === 'otica'}
                    />
                    <LinkMenu 
                        descricao='Óculos de sol' 
                        href="/produtos/oculos-de-sol" 
                        feminino={categorias['oculos-de-sol'].feminino}
                        masculino={categorias['oculos-de-sol'].masculino}
                        focado={categoria === 'oculos-de-sol'}
                    />
                    <LinkMenu 
                        descricao='Acessórios' 
                        href="/produtos/acessorios" 
                        feminino={categorias['acessorios'].feminino}
                        masculino={categorias['acessorios'].masculino}
                        focado={categoria === 'acessorios'}
                    />
                    <LinkMenu 
                        descricao='A loja'
                        href="/#a-loja"
                        link={true}
                    />
                    <LinkMenu 
                        descricao='Contato' 
                        href="/#contato"
                        link={true}
                    />

                    <div className="px-2 py-3 busca">
                        <Form.Control 
                            type="text" 
                            placeholder="Faça sua busca" 
                            className="mr-sm-2" 
                            value={pesquisa.descricao}
                            onChange={e => {
                                alterarPesquisa({descricao: e.target.value});
                            }}
                            onKeyDown={e => {
                                if(e.key === "Enter"){
                                    aplicarBusca();
                                }
                            }}
                        />
                        <FontAwesomeIcon 
                            icon={faSearch} 
                            className='icone' 
                            onClick={e => {
                                aplicarBusca();
                            }} 
                        />
                    </div>
                </Nav>
                <Form inline className="redes-sociais desktop">
                    <a variant="outline-success" className="p-2" href="https://www.instagram.com/rhjoalheriaeotica/" target="_blank">
                        <FontAwesomeIcon icon={faInstagram} />
                    </a>
                    <a variant="outline-success" className="p-2" href="https://www.facebook.com/rh.joalheriaeotica/" target="_blank">
                        <FontAwesomeIcon icon={faFacebookF} />
                    </a>
                </Form>
            </Navbar.Collapse>

        </Navbar>
    </CabecalhoComponent>

}