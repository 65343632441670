import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { converterReal } from './../../../../Funcoes';

// componentes
import { ProdutoComponent } from './components';

// temporario
import imgTeste from './hd1.jpg';

export default function Produto({
    dados,
    imagens,
    clicouVerMais
}){

    // produto
    return <ProdutoComponent>
        {imagens.length > 0 &&
            <img className="imagem" onClick={clicouVerMais} src={`${process.env.REACT_APP_URL_ARQUIVOS}/arquivos/geral/${imagens[0].arquivo}`} />
        }
        <div className="dados p-2">
            <a href={`/produto/${dados.id}`}>{dados.descricao.substring(0, 50)}</a>
            { dados.mostrarValor === "S" &&
                <>
                    {dados.valor && <p>R$ {converterReal(dados.valor)}</p>}
                    {(dados.valor && dados.mostrarParcelas === 'S') && 
                        <p>{dados.parcelas}x R$ {converterReal(parseFloat(dados.valor) / parseInt(dados.parcelas))}</p>
                    }
                </>
            }
            <p className="mt-2 compre-agora">
            <a target="_blank" href={`https://api.whatsapp.com/send?phone=5548999763509&text=Tenho%20interesse%20no%20produto%20${window.encodeURI(`${process.env.REACT_APP_URL_SITE}/r/produto/`)}${dados.id}`}><FontAwesomeIcon icon={faWhatsapp} size="2x" className="mr-2" /> COMPRE AGORA</a>
            </p>
        </div>
    </ProdutoComponent>
}