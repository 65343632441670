import styled from 'styled-components';


export const ModalVejaMaisComponent = styled.div.attrs({
    className: `px-4 pb-4`
})`

    /* display: flex;
    flex-wrap: wrap; */
    /* min-height: 30vh; */
    overflow-y: auto;
    max-height: 80vh;

    .descricao-principal{
        font-size: 1.1em;
        margin-bottom: 1.2em;
    }

    p, .descricao{
        white-space: pre-wrap;
        text-align: justify;

        div{
            padding: 0 1em;
        }
    }

    hr{
        margin: 1.5rem 0;
    }

    .bloco-imagem{
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        padding: 1.5rem 0;

        &:first-child{
            border-top: 0;
        }

        .imagem{
            min-width: 35%;
        }
    }

    .imagem {
        cursor: pointer;

        &.imagem-unica{
            text-align: center;

            img {
                height: unset;
                max-width: 100%;
                max-height: 200px;
            }
        }

        img {
            height: 150px;
            object-fit: contain;
        }

    }
    
`