import styled from 'styled-components';


export const ButtonComponent = styled.button.attrs({
    className: "btn rounded-pill px-4"
})`

    border: 1px solid white;
    text-align: center;
    color: white;

    &:hover {
        color: grey;
        border-color: grey;
    }

    ${props => props.primary && `
        color: ${props.theme.primary};
        border-color: ${props.theme.primary};
    `}

`