import styled from 'styled-components';

export const LojaComponent = styled.div.attrs({
    className: `mt-3`
})`

    .imagem {
        
        img {
            max-width: 100%;
        }
    }


    .logotipo {
        text-align: center;

        img {
            max-width: 200px;
        }
    }

    .descricao {

        p {
            color: ${props => props.theme.primary};
            white-space: pre-wrap;
            text-align: justify

        }

        .veja-mais {
            text-align: center;
        }
    }


    /* button {
        background: ${props => props.theme.laranja};
        color: white;

        &:hover {
            color: #ddd;
        }
    } */


`