import styled from 'styled-components';

export const BannersComponent = styled.div.attrs({
    className: ``
})`

   
    .banners {

        .blocos {
            
        }

        .carregando {
            width: 100%;
            min-height: 300px;
            display: flex !important;
            align-items: center;
            justify-content: center;
            color: grey;
            background: #3030308c;
            color: #222222;
            text-shadow: 1px 1px 6px #6c6c6c;
            color: #efefef;
            text-shadow: 1px 1px 6px #6c6c6c;
            font-weight: lighter;
            display: flex;
            flex-direction: column;
            font-size: 1.4rem;
            font-weight: bold;

            .icone {
                font-size: 40px;
                filter: drop-shadow(1px 1px 1px #6c6c6c);
            }
        }

        img {
            width: 100%;
            object-fit: cover;
            max-height: calc(100vh - 170px);
            min-height: 200px;
        }

    }

     
    .slick-custom {
        z-index: 1;
        
        /*
        .slick-dots {

            position: absolute;
            bottom: -0px;
            display: block;
            width: 100%;
            padding: 0;
            text-align: center;

            li {
                
                cursor: pointer;

                &.slick-active {

                    button {

                        &:before {
                            color: black;
                            color: orange;
                            opacity: 0.9;
                        }
                    }
                }

                button {
                    font-size: 0;
                    line-height: 0;
                    display: block;
                    width: 25px;
                    height: 25px;
                    padding: 5px;
                    cursor: pointer;
                    color: transparent;
                    color: red;
                    border: 0;
                    outline: none;
                    background: transparent;
                    position: absolute;


                    &:before {
                        font-family: 'slick';
                        font-size: 20px;
                        line-height: 20px;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 20px;
                        height: 20px;
                        content: '•';
                        text-align: center;
                        color: black;
                        -webkit-font-smoothing: antialiased;
                        color: white;
                        filter:drop-shadow(0 0 3px rgb(196, 196, 196));
                        opacity: 0.8;
                    }
                }
            }
        }
        */
    }
    
    
`