import styled from 'styled-components';

export const LinkMenuComponent = styled.div.attrs({
    className: `p-4`
})`

    position: relative;

    .descricao {
        cursor: pointer;
        text-transform: uppercase;
        text-decoration: none;
        color: #444;
        border-bottom: 1px solid transparent;
        vertical-align: super;
        white-space: nowrap;

        ${props => props.focado && `
            border-bottom: 1px solid black;
        `}

        @media screen and (max-width: 1370px){
            font-size: 0.8rem;
        }

        @media screen and (max-width: 1370px){
            font-size: 0.8rem;
        }

        /* &:hover {
            color: black;
            border-bottom: 1px solid black;
        } */
    }

    .submenu {
        display: none;
        position: absolute;
        background: white;
        left: 0;
        top: 50px;
        white-space: nowrap;
        box-shadow: 0 0 1px 1px #c5c5c5;
        text-transform: uppercase;
        z-index: 10;
        

        ${props => props.habilitar && `
            display: inline-block;
        `}

        @media screen and (max-width: 768px){
            display: none;
        }

        .submenu-itens {
            width: max-content;

            .titulo {
                color: black;
                font-weight: bold;
                font-size: 1.1rem;
            }

            button {
                background: none;
                border: none;
                text-transform: uppercase;
                display: block;

                &:focus {
                    outline: 0;
                }
            }
        }
    }



`