import React, { useState } from 'react';
import axios from 'axios';

// imagens
import logotipoPng from './../../../imagens/logo.svg';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ContatoComponent } from './components';
import { ButtonComponent } from './../components';
import { Row, Col, Form, Button, Container } from 'react-bootstrap';
import { faPhone, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';


// contato componente
export default function Contato({
    dados
}){

    // dados de formulário
    const [enviando, alterarEnviando] = useState(false);
    const [erros, alterarErros] = useState([]);
    const dadosIniciais = {
        assunto: '',
        nome: '',
        telefone: '',
        cidade: '',
        email: '',
        mensagem: '',
        tipo: 'contato'
    };
    const [dadosFormulario, alterarDadosFormulario] = useState({...dadosIniciais});

    // faz o envio
    async function enviar(){
        alterarErros([]);
        alterarEnviando(true);

        try{

            // faz a requisição
            let { data } = await axios.post(`/email`, { dados: dadosFormulario });
            alert('Email enviado com sucesso');
            alterarDadosFormulario({...dadosIniciais});

        }catch({response}){
            if(response && response.status && response.status === 400){
                alterarErros(response.data);
            }
        }finally{
            alterarEnviando(false);
        }
    }

    return <>
        <ContatoComponent
            id="contato"
        >
            <Container>
                <h2>Contato</h2>
                <hr />
                <Row className='align-items-center mt-5'>
                    <Col sm={6}>
                        <div>
                            <div className='logotipo mb-5 p-4'>
                                <img src={logotipoPng} />
                            </div>
                            <div className="descricao p-4">
                                <p>
                                    {dados.texto}
                                </p>
                                <div className="telefones mt-5">
                                    <div className="mb-2">
                                        <FontAwesomeIcon className="icone mr-2" icon={faPhone} />
                                        <span>(48) 3626.1173</span>
                                    </div>
                                    <div>
                                        <a href="https://api.whatsapp.com/send?phone=5548999763509&text=Bom%2C%20estou%20entrando%20em%20contato" target="_blank">
                                            <FontAwesomeIcon className="icone mr-2" icon={faWhatsapp} />
                                            <span>(48) 9 9976.3509</span>
                                        </a>
                                    </div>
                                </div>
                                {/* <p>
                                    Este é um espaço destinado para você entrar em contato com a nossa empresa. Tire dúvidas, peça um orçamento. <br />
                                    Queremos muito ajudar você.
                                </p>
                                <p>
                                    Contato Matriz: <br />
                                    (48) 3622.1717
                                </p>
                                <p>
                                    Contato Matriz São Paulo: <br />
                                    (48) 3622.1717
                                </p>
                                <p>
                                    Contato Matriz Bahia: <br />
                                    (48) 3622.1717
                                </p>
                                <p>
                                    Contato Matriz Sergipe: <br />
                                    (48) 3622.1717
                                </p> */}
                            </div>
                        </div>
                    </Col>
                    <Col sm={6}>
                        <Form.Group>
                            <Form.Label>Assunto</Form.Label>
                            <Form.Control 
                                value={dadosFormulario.assunto}
                                onChange={e => {
                                    dadosFormulario.assunto = e.target.value;
                                    alterarDadosFormulario({...dadosFormulario});
                                }}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Nome</Form.Label>
                            <Form.Control 
                                value={dadosFormulario.nome}
                                onChange={e => {
                                    dadosFormulario.nome = e.target.value;
                                    alterarDadosFormulario({...dadosFormulario});
                                }}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Telefone</Form.Label>
                            <Form.Control 
                                value={dadosFormulario.telefone}
                                onChange={e => {
                                    dadosFormulario.telefone = e.target.value;
                                    alterarDadosFormulario({...dadosFormulario});
                                }}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Cidade</Form.Label>
                            <Form.Control 
                                value={dadosFormulario.cidade}
                                onChange={e => {
                                    dadosFormulario.cidade = e.target.value;
                                    alterarDadosFormulario({...dadosFormulario});
                                }}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Email</Form.Label>
                            <Form.Control 
                                value={dadosFormulario.email}
                                onChange={e => {
                                    dadosFormulario.email = e.target.value;
                                    alterarDadosFormulario({...dadosFormulario});
                                }}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Sua mensagem</Form.Label>
                            <Form.Control 
                                as='textarea' 
                                rows={4} 
                                value={dadosFormulario.mensagem}
                                onChange={e => {
                                    dadosFormulario.mensagem = e.target.value;
                                    alterarDadosFormulario({...dadosFormulario});
                                }}
                            />
                        </Form.Group>
                        <ButtonComponent 
                            className='btn rounded-pill px-4'
                            onClick={enviar}
                            disabled={enviando}
                            primary 
                        >
                                {enviando ?
                                    <>
                                        <FontAwesomeIcon icon={faSpinner} pulse className="mr-1" />
                                        <span>Enviando</span>
                                    </>
                                    :
                                    <>Enviar</>
                                }
                        </ButtonComponent>
                    </Col>
                </Row>
            </Container>
        </ContatoComponent>
    </>

}