import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet';
import axios from 'axios';

// componentes
import { LeituraComponent } from './components';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { converterReal } from './../../../Funcoes';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { ContainerImagemComponente, ImagemComponente } from './../../../Componentes/ModalImagem/components.js';


export default function Leitura({

}){

    const { id } = useParams();
    const [carregando, alterarCarregando] = useState(true);
    const [dados, alterarDados] = useState(null);
    const [arquivos, alterarArquivos] = useState(null);
    const [focar, alterarFocar] = useState(null); 

    // consulta o id do produto
    async function consultarId(){

        try{

            // faz a requisição
            let { data } = await axios.get(`/produtos/${id}`);
            alterarDados(data.dados);
            alterarArquivos(data.arquivos);
            alterarCarregando(false);

        }catch({response}){}

    }

    // inicializa
    useEffect(() => {
        consultarId();
    }, []);

    if(carregando){
        return <div className="m-4 text-center">
            <FontAwesomeIcon icon={faSpinner} pulse /> Aguarde!
        </div>
    }

    return <LeituraComponent>
        <Container
            className="p-5"
        >
            <div className="galeria">
                {arquivos.map(arquivo => 
                    <img 
                        className="imagem img-thumbnail m-2" 
                        src={`${process.env.REACT_APP_URL_ARQUIVOS}/arquivos/geral/${arquivo.arquivo}`} 
                        onClick={e => {
                            alterarFocar(`${process.env.REACT_APP_URL_ARQUIVOS}/arquivos/geral/${arquivo.arquivo}`) 
                        }}
                    />
                )}
            </div>
            <div className="dados">
                <p>{dados.descricao.substring(0, 50)}</p>
                { dados.mostrarValor === "S" &&
                    <>
                        {dados.valor && <p>R$ {converterReal(dados.valor)}</p>}
                        {(dados.valor && dados.mostrarParcelas === 'S') && 
                            <p>{dados.parcelas}x R$ {converterReal(parseFloat(dados.valor) / parseInt(dados.parcelas))}</p>
                        }
                    </>
                }
                <p className="mt-2 compre-agora">
                    <a target="_blank" href={`https://api.whatsapp.com/send?phone=5548999763509&text=Tenho%20interesse%20no%20produto%20${window.encodeURI(`${process.env.REACT_APP_URL_SITE}/r/produto/`)}${dados.id}`}><FontAwesomeIcon icon={faWhatsapp} size="2x" className="mr-2" /> COMPRE AGORA</a>
                </p>
            </div>
        </Container>

        {focar &&
            <Modal 
                show={true}
                size={'lg'}
                centered
                backdrop
                onHide={() => {
                    alterarFocar(null)
                }}
                dialogClassName={'modal-no-background-content'}
                onClick={() => {
                    alterarFocar(null)
                }}
            >
                <ContainerImagemComponente className={'h-auto'} style={{maxWidth: '90vw'}}>
                    <ImagemComponente>
                        <img src={focar} />
                    </ImagemComponente>
                </ContainerImagemComponente>
            </Modal>
        }
    </LeituraComponent>

}