import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// importações
import { createStore, applyMiddleware } from 'redux';
import { createStateSyncMiddleware, initStateWithPrevTab } from 'redux-state-sync';
import { Provider } from 'react-redux';
import 'moment/locale/pt-br';
import { ThemeProvider } from 'styled-components';
import tema from './tema';
import RootReducer from './RootReducer';
import ReactGA from 'react-ga';

// configurações
import './scss/geral.scss';
import './axiosConfig';

// configura google analytics
ReactGA.initialize('UA-187132413-1');
ReactGA.pageview(window.location.pathname + window.location.search);

// inicializa configurações padrão do redux
const config = { blacklist: [] };
const middlewares = [ createStateSyncMiddleware(config) ];
const store = createStore(RootReducer, {}, applyMiddleware(...middlewares));
initStateWithPrevTab(store);


ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <ThemeProvider theme={tema}>
                <App />
            </ThemeProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
