import React, { useState } from 'react';

// componentes
import { Nav, Row, Col } from 'react-bootstrap';
import { LinkMenuComponent } from './components';
import { useHistory } from 'react-router-dom';

// componente
export default function LinkMenu({
    descricao,
    href,
    feminino=[],
    masculino=[],
    link=false,
    focado=false
}){

    // estados
    const history = useHistory();
    const [habilitar, alterarHabilitar] = useState(false);
    
    return <>
            <LinkMenuComponent 
                habilitar={habilitar}
                onMouseLeave={e => {
                    alterarHabilitar(false);
                }}
                focado={focado}
            >
                {link ? 
                    <a className='descricao' href={href}>{descricao}</a>
                    :
                    <div 
                        aria-controls="basic-navbar-nav"
                        className='descricao'
                        onMouseEnter={e => {
                            alterarHabilitar(true);
                        }}
                        onClick={() => {
                            if(href){
                                history.push(`${href}`);
                            }
                        }}
                    >
                        {descricao}
                    </div>
                }
                {(!link && (feminino.length > 0 || masculino.length > 0)) &&
                    <div className="submenu p-4">
                        <div className="submenu-itens">
                            <Row>
                                <Col lg={6}>
                                    <div className='p-3'>
                                        <p className="titulo">Para ela</p>
                                        {feminino.map(opcao => 
                                            <button
                                                key={opcao.id}
                                                onClick={e => {
                                                    history.push({
                                                        pathname: href,
                                                        search: `?feminino[]=S&grupos[]=${opcao.id}`,
                                                        hash: "produtos"
                                                    });
                                                }}
                                            >
                                                {opcao.descricao}
                                            </button>
                                        )}
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className='p-3'>
                                        <p className="titulo">Para ele</p>
                                        {masculino.map(opcao => 
                                            <button
                                                key={opcao.id}
                                                onClick={e => {
                                                    history.push({
                                                        pathname: href,
                                                        search: `?masculino[]=S&grupos[]=${opcao.id}`,
                                                        hash: "produtos"
                                                    });
                                                }}
                                            >
                                                {opcao.descricao}
                                            </button>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                }
            </LinkMenuComponent>
    </>
}