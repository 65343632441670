import React from 'react';

// componentes
import { BannersComponent } from './components';
import Slider from "react-slick";

import temp1Jpg from './hd1.jpg';
import temp2Jpg from './hd2.jpg';

export default function Banners({
    imagens
}){

    // configurações dos banners
    let sliderConfig = {
        arrows: false,
        dots: true,
        infinite: true,
        className: 'slick-custom',
        dotsClass: 'slick-dots',
        adaptiveHeight: true,
        autoplaySpeed: 2500,
        autoplay: true,
        focusOnSelect: true,
        pauseOnHover: false,
        pauseOnFocus: true,
        appendDots: dots => <ul>{dots}</ul>
    };

    let imgs = [
        temp1Jpg,
        temp2Jpg
    ];


    return <BannersComponent>
        <div className='banners'>
            <Slider {...sliderConfig} >
                {imagens.map(imagem => 
                    <img key={imagem.dados.id} alt='' src={`${process.env.REACT_APP_URL_ARQUIVOS}/arquivos/banners/${imagem.dados.arquivo}`} />
                )}
            </Slider>
        </div>
    </BannersComponent>
}