import React, { useState, useEffect } from 'react';

// componentes
import Slider from "react-slick";


// componentes
import { ModalVejaMaisComponent } from './components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, Row } from 'react-bootstrap';

import { ContainerImagemComponente, ImagemComponente } from './../../Componentes/ModalImagem/components.js';

export default function ModalVejaMais({
    titulo,
    texto,
    imagens,
    caminho='/arquivos/geral',
    fechou,
    dados,
    habilitarMapa=false
}){


    const [mostrar, alterarMostrar] = useState(true);
    const caminhoImagens = `${process.env.REACT_APP_URL_ARQUIVOS}${caminho}`;
    const [focar, alterarFocar] = useState(null); 
    const [posicaoScroll, alterarPosicaoScroll] = useState(0);

    // cria referencia
    let ref = React.createRef();

    // configurações dos banners
    let sliderConfig = {
        arrows: false,
        dots: true,
        className: 'slick-custom',
        dotsClass: 'slick-dots',
        adaptiveHeight: true,
        autoplaySpeed: 2000,
        // appendDots: dots => <ul>{dots}</ul>
    };

    const alterarFoco = (foco) => {

        if(foco){

            // adiciona foco e salva posição do scroll
            alterarPosicaoScroll(ref.current.scrollTop)
            alterarFocar(foco) 

        }else{

            // remove foco e mantém posição do scroll
            alterarFocar(foco) 
            if(ref.current){
                ref.current.scrollTo({top: posicaoScroll});
            }
        }
        
    }
    
    return <>
        <Modal 
            show={mostrar} 
            onExited={fechou}
            onHide={() => {
                alterarMostrar(false);
            }}
            size={'lg'}
            hidden={focar !== null}
            className="border-0"
            centered
        >
            {
                titulo &&
                <Modal.Header className='border-0 px-5'>
                    <Modal.Title>
                        {titulo}
                    </Modal.Title>
                </Modal.Header>
            }
            <Modal.Body>
                <ModalVejaMaisComponent ref={ref}>
                    {
                        texto && texto.trim() !== '' && 
                        <p className={"descricao-principal"}>
                            {texto}
                        </p>
                    }
                        {
                            (imagens && imagens.length > 0) && 
                            <Row className="py-3">
                                {
                                    imagens.map((imagem, a) => 
                                        <div key={imagem.id} className='imagem p-2' onClick={() => { 
                                            alterarFocar(`${caminhoImagens}/${imagem.arquivo}`) 
                                        }}>
                                            <img className="rounded" src={`${caminhoImagens}/${imagem.arquivo}`} />
                                        </div>
                                    )
                                }
                            </Row>
                        }
                    {
                        dados &&
                        dados.filter((item) => item.descricao ? true : false).map((item, a) => {
                            return <div key={item.id} className={['bloco-imagem d-flex flex-grow-1', (a % 2 !== 0) ? 'flex-row-reverse' : ''].join(' ')}>
                                <div className={['imagem p-2 imagem-unica'].join(' ')} onClick={() => { 
                                        alterarFoco(`${caminhoImagens}/${item.arquivo}`) 
                                    }}>
                                    <img className="rounded" src={`${caminhoImagens}/${item.arquivo}`} />
                                </div>
                                <div className="flex-grow-1 descricao py-2 px-4">
                                    <p>{item.descricao}</p>
                                </div>
                            </div>
                        })
                    }

                    {habilitarMapa &&
                        <div>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3506.9926513065284!2d-49.00740241081588!3d-28.479767956823814!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x952142fb519cd725%3A0x8f61dae6f0cf8bcb!2sRH%20Joalheria%20e%20%C3%93tica!5e0!3m2!1spt-BR!2sbr!4v1603322505823!5m2!1spt-BR!2sbr" width="100%" height="300" frameBorder="0" style={{border: 0}} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
                        </div>
                    }
                </ModalVejaMaisComponent>

                {focar &&
                    <Modal 
                        show={true}
                        size={'lg'}
                        centered
                        backdrop
                        onHide={() => {
                            alterarFoco(null)
                        }}
                        dialogClassName={'modal-no-background-content'}
                        onClick={() => {
                            alterarFoco(null)
                        }}
                    >
                        <ContainerImagemComponente className={'h-auto'} style={{maxWidth: '90vw'}}>
                            <ImagemComponente>
                                <img src={focar} />
                            </ImagemComponente>
                        </ContainerImagemComponente>
                    </Modal>
                }

            </Modal.Body>
        </Modal>

    </>
}