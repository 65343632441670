import React, { useEffect, lazy, Suspense } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import { useSelector  } from 'react-redux';

// componentes
import Site from './Site';

// // componentes
// import Login from './Login';
// import Cadastro from './Cadastro';
// import Painel from './Painel';

const Login = lazy(() => import('./Login'));
const Painel = lazy(() => import('./Painel'));


function Sistema({

}){

    // reducers
    const { loginReducer } = useSelector(state => state);
    
    return <>
        <Router>
            <Switch>
                <Suspense fallback={<div>Carregando... </div>}>
                    <Route path={`/login`}>
                        { loginReducer.logado ? <Redirect to="/painel" /> : <Login /> }
                    </Route>
                    <Route path={`/painel`}>
                        { loginReducer.logado ? <Painel /> : <Redirect to="/login" /> }
                    </Route>
                    <Route exact path={[`/`, `/produtos`, `/produtos/:categoria`]}>
                        <Site />
                    </Route>
                    <Route exact path={[`/produto/:id`]}>
                        <Site produto={true} />
                    </Route>
                </Suspense>
            </Switch>
        </Router>
    </>
}

export default Sistema;