import styled from 'styled-components';

export const ProdutosComponent = styled.div.attrs({
    className: 'mt-5'
})`

    .lista {
        display: grid;
        grid-column-gap: 2rem;
        grid-row-gap: 2rem;
        grid-template-columns: 1fr 1fr 1fr 1fr;

        @media screen and (max-width: 768px){
            grid-template-columns: 1fr 1fr;
        }


        @media screen and (max-width: 411px){
            grid-template-columns: 1fr;
            grid-row-gap: 1rem;
        }
        
    }
`