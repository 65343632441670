import styled from 'styled-components';

export const ContatoComponent = styled.div.attrs({
    className: 'mt-5'
})`

    .logotipo {
        text-align: left;

        img {
            max-width: 200px;
        }
    }

    .descricao {

        p {
            color: ${props => props.theme.primary};
            white-space: pre-wrap;

        }

        .telefones {
            margin-top: 20px;
            text-align: left;
            font-size: 1.3rem;

            > div {
                display: flex;
                align-items: center;
                text-align: center;
                justify-content: flex-start;

                .icone {
                    font-size: 2rem;
                }
            }
        }
    }

    input, textarea {
        border-radius: 0;
    }


    /* button {
        background: ${props => props.theme.laranja};
        color: white;

        &:hover {
            color: #ddd;
        }
    } */

`