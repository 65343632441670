import styled from 'styled-components';

// componente
export const CabecalhoComponent = styled.div.attrs({
    className: `bg-white`
})`

    padding: 1.0rem !important;

    @media screen and (max-width: 412px){
        padding: 0.5rem !important;

        .logo {
            img {
                height:70px;
            }
        }

        .logo-mobile {
            img {
                height:70px;
            }
        }
    }

    @media screen and (min-width: 769px){
        ${props => props.menuFixo > 520 && `
            height: 136px;

            @media screen and (max-width: 1280px){
                height: 122px;
            }

            .navbar {
                position: fixed; 
                top: 0;
                right: 0;
                left: 0;
                background: white;
                z-index: 10;
                box-shadow: 0 0 11px #e0e0e0;
                padding: 0.2rem;

                * {
                    margin-top: 0;
                    margin-bottom: 0;
                    padding-top: 0;
                    padding-bottom: 0;
                }
            }

            .logo {

                img {
                    height: 30px !important;
                }
            }
        `};
    }

    .logo {

        img {
            height: 50px;
        }

        /* @media screen and (max-width: 1430px){
            margin-right: 0 !important;
            margin-left: 0 !important;

            img {
                height: 40px;
            }
        } */

    }

    .itens-menu {
        margin: auto;
    }

    .busca {
        position: relative;

        input {
            border: 0;
            background: transparent;
            border-bottom: 1px solid grey;
            border-radius: 0;
        }

        .icone {
            position: absolute;
            right: 10px;
            top: 0;
            bottom: 0;
            margin: auto;
            font-size: 0.8rem;
            cursor: pointer;
        }
    }

    .redes-sociais, .logo-mobile {

        &.mobile {
            @media screen and (min-width: 769px){
                display: none;
            }
        }

        &.desktop {
            @media screen and (max-width: 768px){
                display: none;
            }
        }

        button, a {
            font-size:1.5rem;
            color: black;
        }
    }

    @media screen and (min-width: 1440px){
        /* background: red !important; */

        > .navbar {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            /* background: red !important; */

            .logo {
                margin: auto !important;
            }

            .redes-sociais {
                margin: auto !important;
            }
        }

        .logo {

            img {
                height: 70px;
            }
        }
    }


    @media screen and (max-width: 1280px){

        .logo {

            img {
                height: 50px;
            }
        }
    }

`;
